/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/styles/global.css"

export const onClientEntry = () => {
  window.onload = () => {
    let e = document.createElement("script");

    e.async = 1;
    e.src = "https://wchat.eu.freshchat.com/js/widget.js";

    e.onload = () => {
        window.fcWidget.init({
        token: "0e5b3e01-fc35-4539-987a-7b57c3f6c471",
        host: "https://wchat.eu.freshchat.com",
        content: {
          headers: {
            chat: 'foo',
            chat_help: 'foobar'
          }
        }
      });
    }

    document.head.appendChild(e);
  }
}